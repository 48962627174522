import { MediaItem } from 'interfaces/content/Media';

export interface SectionArticle {
  slug: string;
  title: string;
  eyebrowTagLabel: string;
  eyebrowSectionLabel: string;
}

export interface FeaturedArticle extends SectionArticle {
  mediaItem: MediaItem;
}

export interface SectionArticles {
  name: string;
  slug: string;
  featuredArticle: FeaturedArticle;
  articles: SectionArticle[];
}

export interface SectionSlug {
  slug: string;
}

export enum SectionVariant {
  HomeLatestArticles = 'home-latest-articles',
  Section = 'section',
  SubSection = 'subsection',
  TopPicks = 'top-picks',
  SectionLatestArticles = 'section-latest-articles',
  LatestArticles = 'latest-articles',
  MoreArticles = 'more-articles',
  TopArticles = 'top-articles',
  TopArticles2 = 'top-articles-2',
}
