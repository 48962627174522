import styled from 'styled-components';
import { font as fontFamily, breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

type TagName = 'h1' | 'h2' | 'h3';

type FontName = 'saolText' | 'graphikCompact' | 'graphikCond' | 'georgia';

type TypeStyle =
  | 'base'
  | 'step-down-three'
  | 'step-down-two'
  | 'step-down-one'
  | 'step-up-one'
  | 'step-up-two'
  | 'step-up-three'
  | 'step-up-four'
  | 'step-up-five'
  | 'step-up-six';

export interface ContentTitleProps {
  children: React.ReactNode;
  font?: FontName;
  size?: number;
  tag?: TagName;
  typeStyle?: TypeStyle;
  className?: string;
  dataCy?: string; // eslint-disable-line react/no-unused-prop-types
}

const Tag = styled.h1<{ theme: Theme; $font: string; $size: number }>`
    font-family: ${({ $font }) => $font};
    font-weight: 600;
    
    ${({ $size }) => ($size > 0 ? `font-size: ${$size}px;` : '')};
    a {
      color: inherit;
      text-decoration: none;
    }
  
    em {
      font-style: italic;
    }
  
    strong {
      font-weight: 700;
    }

    &.right-rail {
      border-bottom: 1px solid grey;
      font-weight: 600;
      line-height: 24px;
      font-size: 34px;
      margin: 0px;
      padding-bottom: 8.5px;
      text-transform: capitalize;
      margin-top: 5px;
    }

    &.step-down-three {
      font-size: 12px;
    }
    
    &.step-down-two {
      font-size: 14px;
    }
    
    &.step-down-one {
      font-size: 18px;
    }
    
    &.base {
      font-size: 20px;
    }
    
    &.step-up-one {
      font-size: 26px;
    }
    
    &.step-up-two {
      font-size: 32px;
    }
    
    &.step-up-three {
      font-size: 34px;
    }
    
    &.step-up-four {
      font-size: 39px;
    }
    
    &.step-up-five {
      font-size: 48px;
    }
    
    &.step-up-six {
      font-size: 60px;
    }

    &.heading {
      border-bottom: ${getColor('borderTertiary')} 1px solid;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 26.4px;
      margin-bottom: var(--Heading-Margin);
      text-align: left;

      @media (${breakpoint.lgMin}) {
        font-size: 34px;
        line-height: 41px;
        margin-top: 0px;
      }
    }
  `;

export const ContentTitle = ({ className, children, font, size, tag = 'h1', typeStyle, dataCy }: ContentTitleProps) => {
  const fontTag = font ? fontFamily[font] : fontFamily.graphikCond;

  return (
    <Tag
      as={tag}
      className={`${className || ''} ${typeStyle || ''}`}
      $font={fontTag}
      $size={size || 0}
      data-cy={dataCy}
    >
      {children}
    </Tag>
  );
};

export const PackagesContentTitle = ({ className, children, font, size, tag = 'h1', typeStyle }: ContentTitleProps) => {
  const fontTag = font ? fontFamily[font] : fontFamily.graphikCond;

  return (
    <Tag
      as={tag}
      className={`${className || ''} ${typeStyle || ''}`}
      $font={fontTag}
      $size={size || 0}
      style={{
        lineHeight: '120%',
      }}
    >
      {children}
    </Tag>
  );
};
