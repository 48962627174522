import React, { useContext, useEffect } from 'react';
import HomePageInStreamList from 'components/Ad/AdSlot/HomePageInStreamList';
import FeaturedPrimary, { FeaturedPrimaryProps } from 'components/Home/FeaturedPrimary';
import FeaturedSecondary, { FeaturedSecondaryProps } from 'components/Home/FeaturedSecondary';
import NewsletterBlock, { NewsletterBlockProps } from 'components/Home/NewsletterBlock';
import SectionsBlock, { SectionsBlockProps } from 'components/Home/SectionsBlock';
import Plea from 'components/Home/Plea';
import { Plea as PleaProps } from 'interfaces/content/Media';
import { useRouter } from 'next/router';
import { NoticeContext } from 'providers/NoticeProvider';
import HiddenHeaderTags from './HomePageStyles';

const ComponentMap = {
  featuredPrimary: FeaturedPrimary,
  featuredSecondary: FeaturedSecondary,
  newsletterBlock: NewsletterBlock,
  sectionsBlock: SectionsBlock,
  subscriptionPlea: Plea,
};

interface FeaturedPrimaryItem {
  component: 'featuredPrimary';
  key: string;
  props: FeaturedPrimaryProps;
}

export interface FeaturedSecondaryItem {
  component: 'featuredSecondary';
  key: string;
  props: FeaturedSecondaryProps;
}

interface NewsletterBlockItem {
  component: 'newsletterBlock';
  key: string;
  props: NewsletterBlockProps;
}

interface SectionsBlockItem {
  component: 'sectionsBlock';
  key: string;
  props: SectionsBlockProps;
}

interface SubscriptionPleaItem {
  component: 'subscriptionPlea';
  key: string;
  props: PleaProps;
}

export type HomepageItem =
  | FeaturedPrimaryItem
  | FeaturedSecondaryItem
  | NewsletterBlockItem
  | SectionsBlockItem
  | SubscriptionPleaItem;

export interface HomeProps {
  items: HomepageItem[];
}

const HomePage: React.FC<HomeProps> = ({ items }) => {
  const firstFPIdx = items.findIndex((item) => item.component === 'featuredPrimary');
  const router = useRouter();
  const { setNotification } = useContext(NoticeContext);

  useEffect(() => {
    if (!router.isReady) return;
    // user lands on homepage after Fortune Newsletter DOI
    if (router.query.doi) {
      setNotification?.(
        'success',
        'Thank you for confirming your email address. Please allow up to 72 hours to begin receiving emails from Fortune.',
      );
      // Remove 'doi' from the query
      // eslint-disable-next-line no-unused-vars
      const updatedQuery = { ...router.query };
      delete updatedQuery.doi;

      // Update the router without 'doi'
      // eslint-disable-next-line no-void
      void router.push({ pathname: router.pathname, query: updatedQuery }, undefined, { shallow: true });
    }
  }, [router, router.isReady, setNotification]);

  return (
    <>
      <HiddenHeaderTags>
        <h1>Fortune</h1>
        <h2>Fortune - Fortune 500 Daily & Breaking Business News | Fortune</h2>
      </HiddenHeaderTags>
      <div id='content'>
        <HomePageInStreamList
          startAt={2}
          frequency={4}
        >
          {items.map((item, index) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const Component = ComponentMap[item.component] as any;
            return (
              <Component
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.key}-${index}`}
                className='homepage-block'
                {...item.props}
                isFirstPrimary={index === firstFPIdx}
              />
            );
          })}
        </HomePageInStreamList>
      </div>
    </>
  );
};

export { HomePage };
