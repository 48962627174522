import styled from 'styled-components';
import { breakpoint, color } from 'styles/globals';

// eslint-disable-next-line import/prefer-default-export
export const RightRail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  
  & > .eduRightRailNav:only-child{
    width: 100%;
  }

  @media (${breakpoint.mdMin}) {
    flex-shrink: 0;

    .video-module {
      padding-left: 24px;
      border-left: 4px solid ${color.lightBlue};
    }
  }

  @media (${breakpoint.lgMin}) {
      flex-basis: 336px; 
      margin-bottom: 24px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125) ) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    flex-basis: 328px; 
  }
`;
