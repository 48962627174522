import { LatestArticle } from 'interfaces/content/articles/Articles';
import { HomeLatestNewsContentItem } from 'components/ContentItem';
import Link from 'components/Globals/Base/Link';
import { LATEST_NEWS_TEST_IDS } from 'constants/testsIds/homepage';
import { Wrapper, Title, List } from './LatestNewsStyles';

export interface HomeLatestNewsProps {
  title: string;
  latestNews: LatestArticle[];
}

const HomeLatestNews = ({ title, latestNews }: HomeLatestNewsProps) => {
  const trackingData = {
    eventAction: 'latest',
    eventCategory: 'article',
    eventLabel: 'header click: latest',
    nonInteraction: 0,
  };

  return (
    <Wrapper data-cy={LATEST_NEWS_TEST_IDS.LATEST_NEWS}>
      <Title
        as='h3'
        $fontFamily='saol'
        $size={{ default: 'level3', lgMin: 'level2' }}
      >
        <Link
          href='/the-latest/'
          ariaLabel='Go to the latest news page'
          trackingData={trackingData}
          dataCy={LATEST_NEWS_TEST_IDS.TITLE}
        >
          {title}
        </Link>
      </Title>
      <List>
        {latestNews.length > 0 &&
          latestNews.map((entry) => (
            <HomeLatestNewsContentItem
              key={entry.title}
              title={entry.title}
              titleLink={entry.titleLink}
              section={entry.eyebrowSectionLabel}
              date={entry.postDateGmt ? entry.postDateGmt : entry.date}
            />
          ))}
      </List>
    </Wrapper>
  );
};

export default HomeLatestNews;
